<template>
  <div class="search">
    <!-- 上搜索背景 -->
    <div class="sousuo-beijing">
      <img src="../assets/image/bangzhuzhongxin.png"/>
    </div>
    <div class="case-list">
       <div class="case-list-guizezhongxin">
          <div class="case-guizezhongxin-daohang">
            <div class="changjian-wenti">常见问题</div>
            <div class="caidan-kuan">
                <el-tree :data="datalist" :props="defaultProps" node-key="id"  :expand-on-click-node="false"
                  :filter-node-method="filterNode"
                  ref="tree"
                  :default-expand-all="false"  current-node-key="id" @node-click="handleNodeClick">
                  <!-- <span class="span-ellipsis" slot-scope="{ node, data }">
                      <el-tooltip class="item" effect="light" :content="node.label||''" placement="top-start">
                        <span>{{ node.label }}</span>
                      </el-tooltip>
                  </span> -->
                </el-tree>
             
            </div>
          </div>
          <div class="case-guizezhongxin-rightcenter">
            <!-- 选择的标题 -->
              <!-- <div class="case-xuan-title">选择--二选择</div> -->
              <div class="case-xuan-title">
                <div v-for="(item,index) in getfrounames(typid)">
                  <span :class="{'act':index == getfrounames(typid).length - 1}">{{ item }}</span>
                  <span v-if="index != getfrounames(typid).length - 1">></span>
                </div>
              </div>
              <!-- 全部列表 -->
              <infolist v-if="!showinfo" :typid="typid" :key="pagekey" @listcli="listcli"></infolist>
              <!-- 全部列表详情 -->
              <infodetails v-if="showinfo" :id='lsitid' :key="pagekey"></infodetails>
          </div>
       </div>
    </div>
  </div>
</template>


<script>
import infolist from '../views/infolist.vue'
import infodetails from '../views/infodetails.vue';

  export default {
    name:"case",
    components: { infolist,infodetails},
    data () {
      return {
        datalist: [],
        defaultProps: {
          children: 'child',
          label: 'title'
        },
        tooltipContent:'',
        showinfo:false,
        typelist:{
          id:'0'
        },
        typid:"0",
        pagekey:0,
        lsitid:'',
        subname:''
      }
    },
    mounted () {
     this.hopefenlei()
    },
    methods: {
      //追溯路由
      getfrounames(id){
        if(this.datalist.length == 0 ){
          return ''
        }
        let frouname = []
        this.findParent(this.datalist,id,frouname)
        frouname.unshift('帮助目录')
        if(this.subname != ''){
          frouname.push(this.subname)
        }
        return frouname
      },

      //递归
      findParent(data, target, result) {
        console.log(data,target,result,'gsfshfg')
        for (let i in data) {
          let item = data[i]
          if (item.id == target) {
            result.unshift(item.title)
            return true
          }
          if (item.child && item.child.length > 0) {
            let ok = this.findParent(item.child, target, result)
            if (ok) {
              result.unshift(item.title)
              return true
            }
          }
        }
        return false
      },
      //点击列表
      listcli(e){
        console.log(e)
        this.lsitid = e.typeid
        this.showinfo = true
        this.subname = e.typename
        //this.pagekey += 1
      },
      // 分类
      hopefenlei(){
        let that = this
        that.$http({
          url: that.baseurl +"/getHelpCenterIndex?id="+ that.typelist.id,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                // console.log(res.data.data,'fdgdfg')
                  this.datalist = res.data.data;
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      handleNodeClick(data,node) {
        console.log(node.data.title,'RRR');
        this.tooltipContent = node.data.title;
        this.showinfo = false
        this.typid = data.id 
        this.pagekey += 1
        this.subname = ''
      }
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    background-color: #F2F5FB ;
  }
  .sousuo-beijing{
    width: 100%;
    height: 418px;
    margin-top: 60px;
    /* background: url('../assets/image/bangzhuzhongxin.png') no-repeat; 
    background-size: 100% 100%; */
    position: relative;
    overflow: hidden;
  }
  .sousuo-beijing img{
    width: 100%;
    height: 100%;
  }
  .sousuo-beijing-kuang{
    position: absolute;
    top:50%;
    left:40% ;
    width: 570px;
    height: 56px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DCDFE6;
    padding: 0 20px;
  }

  .case-list{
    width: 100%;
    max-width: 1520px;
    /* height: 500px; */
    margin: 0px auto;
    padding-bottom: 20px;
  }
  .case-list-guizezhongxin{
    width: 100%;
    display: flex;
    justify-content: space-between;
     margin-top: 40px;
  }
  .case-guizezhongxin-daohang{
    width: 18%;
    /* min-height: 440px; */
    background-color: #fff;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    border-radius: 8px;
    border: 0px solid #979797;
  }
  .caidan-kuan{
    width: 90%;
    margin: 0 auto;
    padding: 16px;
  }
  .changjian-wenti{
    width: 100%;
    height: 70px;
    background-color: #275AF5;
    border-radius: 8px 8px 0px 0px;
    text-align: center;
    line-height: 70px;
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 550;
  }
  .case-guizezhongxin-rightcenter{
    width: 80%;
    min-height: 1034px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    border-radius: 8px;
    border: 0px solid #979797;
  }
   .case-xuan-title{
    padding: 24px;
    display: flex;
    align-items: center;
    color: #999;
    font-size: 14px;
  }
  .case-xuan-title .act{
    color: #333;
  }
  .case-list-zhanshi{
    width: 96%;
    margin: 15px auto;
    height: 166px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    border-radius: 8px;
    border: 1px solid #F1F1F1;
  }
  .case-zhanshi-leibiao{
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .case-zhanshi-leibiao-title{
    width: 80%;
    margin-top: 24px;
  }
  .case-riqi{
    font-size: 14px;
    color: #999999;
  }
  .case-riqi-erwei{
    font-weight: 550;
    font-size: 16px;
    color: #333333;
    padding: 24px 0 24px 0;
  }
  .case-zhanshi-leibiao-imgzhan{
    width: 17%;
    margin-top: 24px;
  }
  .case-zhanshi-leibiao-imgzhan img{
    width: 180px;
    height: 118px;
    border-radius: 8px;
  }
  .chakanren-list{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #999999;
  }
  .case-quanbu-list{
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE ;
    padding: 25px 0;
    position: relative;
  }
  .quanbu-list-mingzi{
    font-weight: 550;
    font-size: 16px;
    color: #333333;
  }
  .quanbu-list-mingzi::after{
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color:#275AF5;
    opacity: 0.5;
    position: absolute;
    left: -12px;
    top: 35px;
  }
  .riqi-quanbu{
    font-size: 14px;
    color: #999999;
  }
</style>
<style >
.sousuo-beijing-kuang .el-input__inner{
    width: 85%;
    height: 56px !important;
    border-radius: 8px !important;
    border: none !important;
}
.caidan-kuan .el-tree-node.is-current>.el-tree-node__content {
    background-color:rgba(39,90,245,0.03); /* 你想要的背景色 */
}
.caidan-kuan .el-tree-node:focus>.el-tree-node__content {
  font-size: 14px !important;
  color: #275AF5 !important; /* 你想要的字体颜色 */
  font-weight: 550 !important;
}
.caidan-kuan .el-tree .el-tree-node__content {
    color: #333333; /* 默认字体颜色 */
}
.caidan-kuan .el-tree-node__content {
    height: 53px;
}
/* .caidan-kuan .el-tree-node__label{
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.span-ellipsis{
   max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */
.el-tooltip__popper.is-light{
  color: #FFFFFF;
  background-color: #303133;
}
.caidan-kuan .is-current{
  background-color:#EEF2FF;
}
</style>
